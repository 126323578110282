import React from "react";
import { Link } from "gatsby";
import { Button, Container, Row, Col, Form, Breadcrumb } from 'react-bootstrap';
import BannerImg from "../images/banner-img2.jpg";
import BannerImgTab from "../images/banner-img2-tab.jpg";
import BannerImgMb from "../images/banner-img2-mb.jpg";
import DateFormat from "../components/format-date";
import Breadcrumbs from "../components/breadcrumbs";
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform";
import { useLocation } from "@reach/router"
import Vimeo from '@u-wave/react-vimeo';
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import { Twitter, Facebook, Linkedin, VideoPlayButton } from '../components/icon';

const InnerBanner = (props) => {
  if(props.videourl !=null) {
    var videoid = getVideoId(props.videourl);
  } else {
    var videoid = ''
  }

  const location = useLocation();
  const [showVideo, setShowVideo] = React.useState(false);
  const [isPlay,setPlay] = React.useState(false);
  const trackerVideo = (event) => {    
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
		  'event': 'Video Play Btn',
		  'formType': event,
		  'formId': 'Video Play',
		  'formName': 'Video',
		  'formLabel': 'Video'
		});
	  }

  let processedImages = JSON.stringify({});
  if (props?.imagetransforms?.Banner_Image_Transforms) {
      processedImages = props.imagetransforms.Banner_Image_Transforms;
  }
  return (

<section className="banner-details">
        {props.BannerImg &&
        <div className="details-banner-img">
          <picture>
            <ImageTransform imagesources={props.BannerImg.url} renderer="srcSet" imagename="news-events.Banner_Image.newsbigimg"attr={{ alt: props.Title+ ' - Orlando Reid' }} imagetransformresult={processedImages} id={props.id}/>

          </picture>
          <div className="video-play">
            {showVideo &&
            <YouTube video={videoid.id} autoplay showRelatedVideos={0} onEnd={e => {setPlay(false);setShowVideo(false)}}
            modestBranding={1} onPlaying={trackerVideo(props.Title)}
            />
            }
          </div>
        </div>
        }
        <div className="details-caption">
          <div className="container">
            <Row>
              <Col lg={8}>
                <Breadcrumbs alias={props.alias} detailname = {props.Title}/>
                <h1>
                  {props.Title}
                </h1>
                <div className="d-flex align-items-center flex-wrap">
                {props.NewsDate !=null &&
                <span className="date">
                <DateFormat date={props.NewsDate} />
                </span>
                }
                  <ul className="social-media d-flex align-items-center">
                    <li>Share</li>
                    <li>
                      <a href={`https://twitter.com/intent/tweet?url=${location.href}`} target="_blank">
                        <Twitter />
                      </a>
                    </li>
                    <li>  
                      <a href={`https://www.facebook.com/sharer?u=${location.href}`} target="_blank">
                        <Facebook />
                      </a>
                    </li>
                    <li>
                      <a href={`https://www.linkedin.com/cws/share?url=${location.href}`} target="_blank">
                        <Linkedin />
                      </a>
                    </li>
                  </ul>
                  {props.videourl !=null && props.videourl !='' &&
                  <div className="btn-wrap">
                    <button className="play-btn" onClick = { e => {setPlay(true);setShowVideo(true)}}>
                      <VideoPlayButton /> Play Video
                  </button>
                  </div>
                  }
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      )
}
export default InnerBanner
