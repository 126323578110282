import React, { useState } from "react";
import ReactMarkdown from "react-markdown/with-html"
import Modal from 'react-bootstrap/Modal'
import PropertyReport from "./download-property-report"
const BlogPageSidebar = (props) => {
	console.log("props=>",props?.Sidebar_News_Content);
	const [show, setShow] = useState(false);
	return (
		<div className="blog-newsletter text-center">
			<div>
				<ReactMarkdown source={props?.Heading} escapeHtml={false} />
				{props?.Show_Property_Report == true ?
					<div class="btn-wrap d-block">
						<a class="btn btn-default btn-block" onClick={() => setShow(true)} href="javscript:;">GET PROPERTY REPORT</a>
					</div>
					:
					props?.Sidebar_News_Content == true ?
					<div class="btn-wrap d-xl-block blog-page-btns">
						<a href="/instruct-us-now/" class="btn btn-success mb-4">Instructs us Now</a>
					</div>
					:
					<div class="btn-wrap d-xl-block blog-page-btns">
						<a href="/property-valuation/free-property-valuation-in-clapham-battersea-and-manchester" class="btn btn-success mb-4">Book a valuation</a>
						<a href="/instruct-us-now/" class="btn btn-success mb-4">Instruct us Now</a>
						<a class="btn btn-default" onClick={() => setShow(true)} href="javscript:;">Get your Free Online Property report</a>
					</div>
				}
			<Modal
				show={show}
				centered={true}
				onHide={() => setShow(false)}
				dialogClassName="modal-90w modal-form"
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Modal.Header closeButton>
					<Modal.Title id="example-custom-modal-styling-title">
						Get Your Full Property Report
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<PropertyReport subject={props?.Title} />
				</Modal.Body>
			</Modal>
		</div>
		</div>
	);
}
export default BlogPageSidebar;