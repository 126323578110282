import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from "react-markdown/with-html"
//import NewsletterForm from "./forms/newsletter-form";
import StbTTSAudioPlayer from "./stb-tts-audio-player";
import BlogPageSidebar from "./forms/blogpage-sidebar";
import InvestorForm from "./forms/investor-form";
import { useStaticQuery, graphql } from "gatsby";
import { VideoPlayButton } from './icon';
import Subscribe from "../images/subscribe.png";
import { Helmet } from "react-helmet";

const InnerDetails = (props) => {
  const data = useStaticQuery(graphql`
    query JoinnewsletterQuery {
      strapiGlobalConfig {
        BlogPage_Newsletter_Sidebar_Content
      }
  }
`)

//
  return (

      <section className="static-content">
        <Helmet><script async src="//www.instagram.com/embed.js"></script></Helmet>
        <Container>
          {/* static block1 start */}
          <Row>
            <Col xl={8}>
              {/* static text start */}
              <StbTTSAudioPlayer contentSelector='#tts-content' />
              <div id="tts-content" className="static-text">
                <div className="static-text-wrap">
                  <ReactMarkdown source={props.AboveContent} escapeHtml={false}/>
                  <div className="vedio-card">
                    {props.videobg !=null &&
                    <>
                    <img src={`https://orlandoreid-strapi.q.starberry.com${props.videobg.url}`} />
                    <button className="btn-play">
                      <VideoPlayButton />
                    </button>
                    </>
                    }
                  </div>                  
                </div>
                <div className="long-text-wrap">
                  <ReactMarkdown source={props.BelowContent} escapeHtml={false}/>
                </div>
                {props.Content !=null &&
                  <div className="long-text-wrap">
                    <ReactMarkdown source={props.Content} escapeHtml={false}/>
                  </div>
                }
                
              </div>
              {/* static text end */}
            </Col>
            <Col xl={4} className="d-xl-block blog-page-newsletter">
              {/* newsletter block start */}
              <div className="newsletter-wrap">
                {/* <NewsletterForm Heading={data.strapiGlobalConfig.Join_Our_Newsletter_Sidebar_Content} /> */}
                <BlogPageSidebar Heading={data?.strapiGlobalConfig?.BlogPage_Newsletter_Sidebar_Content}/>
                {props.PageName == "News Events" &&
                  <div style={{marginTop: '40px', textAlign: 'center'}}>
                    <InvestorForm Heading={data?.strapiGlobalConfig?.Join_Our_Newsletter_Sidebar_Content} />
                  </div>
                }
                <div className="d-lg-none subscribe text-center d-md-flex align-items-md-center justify-content-md-center animate__animated">
                  <img src={Subscribe} alt="img" />
                  <span>
                    <strong>Stay up to date </strong>
                with our new videos by
                <a href="https://www.youtube.com/channel/UCR0ob8Yam7B9tuG-81So3GA" target='_blank'> subscribing to our YouTube</a>
                  </span>
                </div>
              </div>
              {/* newsletter block end */}
            </Col>
          </Row>
          {/* static block1 end */}
        </Container>
      </section>
      )
}


export default InnerDetails
