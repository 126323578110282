import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import RadioField from './elements/radio'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import ReactMarkdown from "react-markdown/with-html"
import axios from "axios"
import * as qs from "query-string"



function MySimpleForm(props) {
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
    {
      element: "config",
      formname: "PropertyReport",
      form_type: "contact",
      error_text: "Highlighted fields are required",
      success_text: "We appreciate your interest in submitting the property report form.",
      email_temp_user: "property_report_user",
      email_temp_admin: "property_report_admin",
      email_server_func: "propertyreport",
      event_tracking: "propertyreport",
      page_url: "/general-enquiry"
    },

    {
      grpmd: "12",
      label: "Name",
      placeholder: "Your Name",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18"
    },
    {
      grpmd: "12",
      label: "Telephone",
      placeholder: "Your Telephone Number",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "content_b-18"

    },   
    {
      grpmd: "12",
      label: "Email",
      placeholder: "Your Email Address",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      labelClass: "content_b-18"

    },
    {
      grpmd: "12",
      label: "Address",
      placeholder: "Address",
      name: "address",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18"
    },
    {
      grpmd: "12",
      label: "Number of Bedrooms",
      placeholder: "Number of Bedrooms",
      name: "bedrooms",
      type: "number",
      element: "select",
      required: true,
      values: ['1', '2', '3', '4+'],
      labelClass: "content_b-18",
      class: "full_wdth_box"
    },
    {
      grpmd: "12",
      label: "Have outside space?",
      placeholder: "Have outside space?",
      name: "outsidespace",
      type: "text" ,
      element: "select",
      required: true,
      values: ['Yes', 'No'],
      labelClass: "content_b-18",
      class: "full_wdth_box"
    },
    
    {
      grpmd: "12",
      name: "Submit",
      type: "submit",
      element: "button",
      value: "submit",
      class: "btn btn-success",
      labelClass: "content_b-18"
    },
    {
      text: 'By clicking submit, you agree to our<a href="/terms-and-conditions/"> Terms &amp; Conditions </a> and<a href="/privacy-policy/"> Privacy Policy </a>',
      element: "html",
      class: "terms"
    },
    {
      element: "captcha",
      class: "py-2",
      captchaRef: recaptchaRef
    },
  ]);

  const handlechange = event => {
    // remove initial empty spaces
    if(event.type === "select"){
      event.value = event.value.trimStart()
    }else{
      event.target.value = event?.target?.value.trimStart()
    }
    
  }

  useEffect(() => {
    if (token !== '') {

      const processFromData = async () => {

        let formData = new FormData();

        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(apiRes => {

          formvalues['g_recaptcha_response'] = token;

          // lets send mail
          const axiosOptions_email = {
            url: '/.netlify/functions/server/' + fields[0].email_server_func,
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs.stringify(formvalues),
          }

          axios(axiosOptions_email)
            .then(response => {
              console.log('mail sent!')
            })
            .catch(err =>
              console.log(err)
            );
        });

        const axiosOptions_netlify = {
          url: fields[0].page_url,
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: qs.stringify(formvalues),
        }

        axios(axiosOptions_netlify)
          .then(response => {
            console.log('data stored')
          })
          .catch(err =>
            console.log(err)
          );
        const url = typeof window !== 'undefined' ? window.location.href : ''
        if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
          fields[0].formname = 'Get mortgage help';
        }
        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-' + fields[0].event_tracking,
          'formId': 'form-' + fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': fields[0].formname
        });

        setShowerror(false);
        setThankyou(true);
        localStorage.setItem('typeVal', null)
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g_recaptcha_response'] = token;

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);
      // reset form
      const form = event.target
      form.reset();

    }
  };


  return (
    <div className="form stbform chat-form investorpage-form">

      {showerror && <div className="alert-error">
        <p>{fields[0].error_text}</p>
      </div>}

      {showthankyou && <div className="alert-success">
        <p>{fields[0].success_text}</p>
        {/* <p><a href={props.guide} target="_blank" download>Click here to download the property report.</a></p> */}
      </div>}


      <Form className="contact-form" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit} data-netlify="true" netlify-honeypot="bot-field" data-netlify-recaptcha="true">
        
        {/* <p>If you would like to download brochure, then please leave your contact details below.</p> */}
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={props.to_email_id} />
        <input type="hidden" name="subject" value={props.subject} />
        <input type="hidden" name="investor_guide" value={props.guide} />

        <input type="hidden" name="bot-field" />
        {fields.map((field, index) => {
          if ("input" === field.element) {
            return (
              <InputField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                type={field.type}
                fieldClass={field.class}
                placeholder={field.placeholder}
                labelClass={field.labelClass}
                required={field.required}
                key={`${field.element}~${index}`}
                pattern={field.patternchk}
                handlechange={handlechange}
              />
            );
          }
          if ("select" === field.element) {
            return (
              <SelectField
                name={field.name}
                label={field.label}
                grpmd={field.grpmd}
                ref={field.ref}
                required={field.required}
                fieldClass={field.class}
                placeholder={field.placeholder}
                values={field.values}
                key={`${field.element}~${index}`}
                handlechange={handlechange}
                componentprops={props}
                selectedValue={props.typeVal}
              />
            );
          }
          if ("textarea" === field.element) {
            return (
              <TextAreaField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                rows={field.rows}
                fieldClass={field.class}
                labelClass={field.labelClass}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.element}~${index}`}
                handlechange={handlechange}
              />
            );
          }
          if ("checkbox" === field.element) {
            return (
              <CheckboxField
                name={field.name}
                ref={field.ref}
                value={field.value}
                fieldClass={field.class}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.name}~${index}`}
                handlechange={handlechange}
              />
            );
          }
          if ("radio" === field.element) {
            return (
              <RadioField
                name={field.name}
                ref={field.ref}
                value={field.value}
                fieldClass={field.class}
                checked={field.checked}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.name}~${index}`}
                handlechange={handlechange}
                lastchild={field.lastchild}
              />
            );
          }
          if ("html" === field.element) {
            return (
              <HtmlBox
                text={field.text}
                fieldClass={field.class}
                key={`${field.element}~${index}`}
              />
            );
          }
          if ("captcha" === field.element) {
            return (
              <ReCaptchaBox
                fieldClass={field.class}
                captRef={field.captchaRef}
                key={`${field.element}~${index}`}
                handleonVerify={handleonVerify}
              />
            );
          }
          if ("button" === field.element) {
            return (
              <ButtonField
                name={field.name}
                fieldClass={field.class}
                type={field.type}
                value={field.value}
                key={`${field.element}~${index}`}
              />
            );
          }
        })
        }
        <div ref={myRef} />

      </Form>
    </div>
  );
}


const MySimpleFormPage = (props) => (
  <MySimpleForm guide={props.brochure} to_email_id={props.to_email_id} Heading={props.Heading} subject={props.subject} />
)

export default MySimpleFormPage