import React from 'react';
import Slider from "react-slick";
import Img1 from "../images/img1.jpg";
import Img2 from "../images/img2.jpg";
import Img3 from "../images/img3.jpg";
import "slick-carousel/slick/slick.css";
import { Link } from "gatsby";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import DateFormat from "./format-date";
import { Container, Col, Row, Form } from 'react-bootstrap';
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import { VideoPlayButton, Logo } from '../components/icon';

import $ from 'jquery';
import ImageTransform from "./common/ggfx-client/module/components/image-transform";

const RELATED_NEWS = gql`
query GetNews($Title: String!, $_id: String!){   
    newsEvents (sort:"News_Date:desc",where:{Title_ne:$Title,Select_Categories: [{_id:[ $_id ] }]}) {
        Title
        URL
        id
        News_Date
        News_Video_Embed_URL
        Intro_Image {
            url
        }
        imagetransforms
    }
}
`;

function ImageSlider(props) {
    const [showVideo, setShowVideo] = React.useState(false);
    const [videoindex, setVideoindex] = React.useState(false);
    const [isPlay, setPlay] = React.useState(false);
    const trackerVideo = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Video Play Btn',
            'formType': event,
            'formId': 'Video Play',
            'formName': 'Video',
            'formLabel': 'Video'
        });
    }
    const playVideo = (video_index, video_id) => {
        setVideoindex(video_index)
        setShowVideo(true)
    }

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        centerPadding: '240px',

        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: '192px',
                }
            },
            {
                breakpoint: 1293,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: '140px',
                }
            },
            {
                breakpoint: 991,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '0px',
                    autoplay: true,

                }
            }
        ]
    };
    const { loading: relatednewsloading, error: relatednewserror, data: relatednews } = useQuery(RELATED_NEWS, {
        variables: { Title: props.CurrentNews, _id: props.CurrentCategory }
    });
    if (relatednewsloading) return (
        <div className="static-details gql-loading-screen">
            <Container>
                <Logo />
                <div className="gql-loading">Loading ...</div>
            </Container>
        </div>);

    return (
        <div className="news-slider">
            <Slider {...settings}>
                {relatednews.newsEvents.map((item, index) => {
                    var videoid = ""
                    if (item.News_Video_Embed_URL != null && item.News_Video_Embed_URL != '') {
                        videoid = getVideoId(item.News_Video_Embed_URL);
                    }
                    let processedImages = JSON.stringify({});
                    if (item?.imagetransforms?.Intro_Image_Transforms) {
                        processedImages = item.imagetransforms.Intro_Image_Transforms;
                    }
                    return <>
                        <div className="news-block text-center">
                            <figure className={`news-img team_img_${index}`}>
                                <ImageTransform imagesources={item.Intro_Image.url} renderer="srcSet" imagename="news-events.Intro_Image.sliderimg" attr={{ alt: item.Title + ' - Orlando Reid' }}
                                    imagetransformresult={processedImages} id={item.id} testparam={true} />
                                {item.News_Video_Embed_URL != null && item.News_Video_Embed_URL != '' &&
                                    <button className="btn-play" onClick={(e) => { playVideo(index, videoid.id) }}>
                                        <VideoPlayButton />
                                    </button>
                                }
                                {showVideo && videoindex == index &&
                                    <YouTube
                                        video={videoid.id}
                                        autoplay
                                        onEnd={e => { setPlay(false); setShowVideo(false) }}
                                        modestBranding={1}
                                        onPlaying={trackerVideo(item.Title)}

                                    />
                                }
                            </figure>
                            <Link to={`/about-us/stories/${props.caturl}/${item.URL}/`}>
                                <div className="news-title">
                                    <span><DateFormat date={item.News_Date} /></span>
                                    <h3>{item.Title}</h3>
                                </div>
                            </Link>
                        </div>
                    </>
                })}


            </Slider >
        </div>


    )

}

export default ImageSlider;