import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import "animate.css/animate.css";
import Subscribe from "../images/subscribe.png";
import OtherNews from './other-news';
import Videoslist from "./other-videos";
import { useStaticQuery, graphql } from "gatsby";
// import ScrollAnimation from 'react-animate-on-scroll';

function RelatedNews(props) {

  
	const [animation,setAnimation] = useState(false)
	const [myPropertyValue, setMypropertyValue] = useState(0);
	const [showStories, setshowStories] = React.useState(true)
	const [showVideos, setshowVideos] = React.useState(false)
	function VideosClick() {
		setshowVideos(true)
		setshowStories(false)
	}
	function StoriesClick() {
		setshowVideos(false)
		setshowStories(true)
	}

	useEffect(()=>{

		function isInViewport(el) {
		    let top = el.offsetTop;
  			let left = el.offsetLeft;
  			let width = el.offsetWidth;
  			let height = el.offsetHeight;

			  while(el.offsetParent) {
			    el = el.offsetParent;
			    top += el.offsetTop;
			    left += el.offsetLeft;
			  }

			  return (
			    top < (window.pageYOffset + window.innerHeight) &&
			    left < (window.pageXOffset + window.innerWidth) &&
			    (top + height) > window.pageYOffset &&
			    (left + width) > window.pageXOffset
			  );

		}

		const box = document.querySelector('.newsSec');
    const subscribe = document.querySelector('.subscribe');
		document.addEventListener('scroll', handleScroll, {
		    passive: true
		});

		function handleScroll () {
		
  			if(isInViewport(box)) {
  		    
          /** Here one can write animate.css class for animation **/

  				box.classList.add('animate__fadeInUp','animate__slower');  

  			} else {
  				
          box.classList.remove('animate__fadeInUp','animate__slower');
  		
  			}
          if(isInViewport(subscribe)) {
          
          /** Here one can write animate.css class for animation **/
          subscribe.classList.add('animate__fadeInUp','animate__slower');  
          

        } else {
          subscribe.classList.remove('animate__fadeInUp','animate__slower');
      
        }
		}

		return () =>{
			document.addEventListener('scroll', handleScroll);
		}

	},[])
	return (<React.Fragment>

	    <section className="section-news">
		{/* <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} > */}
        <Container className="newsSec">
          <div className="head-sec text-center">
            <span className="sub-title d-none">latest news</span>
            <p className="d-none">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum tempus ultrices porttitor purus.</p>
            <h2 className="d-lg-block">Other News, Insights and Video from Orlando Reid</h2>
			<ul className="category-links d-lg-flex justify-content-md-center">
            {showVideos &&
              <>
              <li><a href="javascript:;" onClick={StoriesClick}>stories</a></li>
              <li className="active"><a href="javascript:;" onClick={()=>VideosClick}>videos</a></li>
              </>
            }
            {showStories &&
              <>
              <li className="active"><a href="javascript:;" onClick={()=>StoriesClick}>stories</a></li>
              <li><a href="javascript:;" onClick={VideosClick}>videos</a></li>
              </>
            }
            </ul>
          </div>

			{showStories &&
				<OtherNews caturl={props.caturl} CurrentNews={props.CurrentNews} CurrentCategory={props.CurrentID} />
			}
			
			{showVideos &&
				<Videoslist data={props.videodata} newsvideos={props.newsvideos}/>
			}
			

          <div className="subscribe text-center d-md-flex align-items-md-center justify-content-md-center animate__animated">
            <img src={Subscribe} alt="img" />
            <span>
              <strong>Stay up to date </strong>
                 with our new videos by
                <a href="https://www.youtube.com/channel/UCR0ob8Yam7B9tuG-81So3GA" target='_blank'> subscribing to our YouTube</a>
            </span>
          </div>
        </Container>
		{/* </ScrollAnimation> */}
      </section>
      
	</React.Fragment>)


}

export default RelatedNews;