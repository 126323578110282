import React, { useEffect, useRef, useState, useCallback } from "react";
import hash from "object-hash";
import { Helmet } from "react-helmet";
//import Cookies from 'js-cookie';

// const getCSRFToken = () => {
//   return Cookies.get('csrftoken');
// };

// Define the endpoints
const CONVERT_PATH = "/tools/convert/tts/";
const REQUEST_PATH = "/tools/request/tts/";
const META_PATH = "/tools/metadata/tts/";

// Determine the current endpoint based on the domain
const getBaseUrl = () => {
  const hostname = window.location.hostname;
  if (hostname.includes("-dev.q.starberry.com") || hostname.includes("-stage.q.starberry.com")) {
    return "https://stbconnect-dev.q.starberry.com";
  } else {
    return "https://stbconnect.q.starberry.com";
  }
};

const getBrowserName = (userAgent) => {
  // The order matters here, and this may report false positives for unlisted browsers.

  if (userAgent.includes("Firefox")) {
    // "Mozilla/5.0 (X11; Linux i686; rv:104.0) Gecko/20100101 Firefox/104.0"
    return "Mozilla Firefox";
  } else if (userAgent.includes("SamsungBrowser")) {
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36"
    return "Samsung Internet";
  } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 12_5_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 OPR/90.0.4480.54"
    return "Opera";
  } else if (userAgent.includes("Edge")) {
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    return "Microsoft Edge (Legacy)";
  } else if (userAgent.includes("Edg")) {
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 Edg/104.0.1293.70"
    return "Microsoft Edge (Chromium)";
  } else if (userAgent.includes("Chrome")) {
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
    return "Google Chrome or Chromium";
  } else if (userAgent.includes("Safari")) {
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6 Mobile/15E148 Safari/604.1"
    return "Apple Safari";
  } else {
    return "unknown";
  }
};

// Utility function to get user metadata
const getUserMetadata = async () => {
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  let metadata = getCookie('stb-tts-tmp-user');

  if (metadata) {
    try {
      metadata = JSON.parse(metadata);
      return metadata;
    } catch (error) {
      console.error("Error parsing user metadata from cookie:", error);
    }
  }

  metadata = {};
  if (typeof window !== "undefined") {
    metadata.userAgent = getBrowserName(navigator.userAgent);
    metadata.language = navigator.language;
    metadata.platform = navigator.platform;
  }

  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    metadata.ip = data.ip;
    document.cookie = `stb-tts-tmp-user=${JSON.stringify(metadata)}; path=/; max-age=3600;`; // Store metadata in cookies for 1 hour
  } catch (error) {
    console.error("Error fetching IP address:", error);
  }

  return metadata;
};


const StbTTSAudioPlayer = ({ contentSelector }) => {
  const audioPlayerElement = useRef(null);
  const loopRef = useRef(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userMetadata, setUserMetadata] = useState(null);

  const BASE_URL = getBaseUrl();
  const STB_TTS_CONVERT = `${BASE_URL}${CONVERT_PATH}`;
  const STB_TTS_REQUEST = `${BASE_URL}${REQUEST_PATH}`;
  const STB_TTS_USERMETA = `${BASE_URL}${META_PATH}`;

  // Generates a unique cookie name based on the content
  const generateCookieName = useCallback((content) => {
    const myTTSVersion = process.env.GATSBY_STB_TTS_VERSION ? `${process.env.GATSBY_STB_TTS_VERSION}-` : '';
    return `stb-tts-${myTTSVersion}${hash(content)}`;
  }, []);

  // Fetches audio data from the TTS API
  const memoizedFetchAudioData = useCallback(async (content) => {
    //const csrftoken = getCSRFToken(); // Fetch the CSRF token here
    const chunks = splitContent(content);
    try {
      const response = await fetch(STB_TTS_CONVERT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Origin': process.env.GATSBY_SITE_URL,
          //'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          text: chunks,
          filename: generateCookieName(content),
          url: window.location.href,
          ...userMetadata, // Include user metadata in the request
        }),
      });

      if (!response.ok) {
        console.error(`Failed to fetch audio: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error fetching audio data: ${error.message}`);
      throw error;
    }
  }, [generateCookieName, STB_TTS_CONVERT, userMetadata]);

  // Splits content into manageable chunks
  const splitContent = (content) => {
    const sentences = content.match(/[^.!?]+[.!?]+/g) || [];
    let chunks = [];
    let currentChunk = '';

    sentences.forEach((sentence) => {
      const trimmedSentence = sentence.trim();
      if (!trimmedSentence) return;

      if ((currentChunk + ' ' + trimmedSentence).length > 1000) {
        chunks.push(currentChunk.trim());
        currentChunk = trimmedSentence;
      } else {
        currentChunk += (currentChunk ? ' ' : '') + trimmedSentence;
      }
    });

    if (currentChunk) {
      chunks.push(currentChunk.trim());
    }

    return chunks;
  };

  // Sanitizes HTML content for safe TTS conversion
  const sanitizeHTML = (html) => {
    html = html.replace(/<\/?(h[1-6])[^>]*>/g, match => match.startsWith('</') ? '. ' : '');
    let sanitizedContent = html.replace(/<(img|video|audio)[^>]*>/g, ' ')
                               .replace(/<[^>]+>/g, ' ');

    const textarea = document.createElement('textarea');
    textarea.innerHTML = sanitizedContent;
    sanitizedContent = textarea.value.replace(/\s+/g, ' ').trim();

    return sanitizedContent;
  };

  // Checks if the current domain is the correct one
  const isCorrectDomain = () => {
    return window.location.origin === process.env.GATSBY_SITE_URL;
  };

  // Sends a POST request to the request endpoint
  const sendAudioRequest = async (cookieName, audioUrl) => {
    //const csrftoken = getCSRFToken(); // Fetch the CSRF token here
    try {
      await fetch(STB_TTS_REQUEST, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Origin': process.env.GATSBY_SITE_URL,
          //'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          filename: '',
          audioUrl,
          url: window.location.href,
          ...userMetadata, // Include user metadata in the request
        }),
      });
    } catch (error) {
      console.error(`Error sending Request:`, error);
    }
  };

  // Sends a POST request to the metadata endpoint
  const sendPlayerEvent = async (eventName, cookieName, audioUrl) => {
    //const csrftoken = getCSRFToken(); // Fetch the CSRF token here
    try {
      await fetch(STB_TTS_USERMETA, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Origin': process.env.GATSBY_SITE_URL,
          //'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          event: eventName,
          filename: '',
          audioUrl,
          url: window.location.href,
          ...userMetadata, // Include user metadata in the request
        }),
      });
    } catch (error) {
      console.error(`Error sending ${eventName} event:`, error);
    }
  };

  // Handles content change and fetches audio data
  useEffect(() => {
    const contentElement = document.querySelector(contentSelector);

    const handleContentChange = async () => {
      if (!isCorrectDomain()) {
        setError("No audio available for this domain.");
        return;
      }

      if (!userMetadata) {
        // Fetch user metadata if not already fetched
        const fetchedMetadata = await getUserMetadata();
        setUserMetadata(fetchedMetadata);
        return; // Wait until metadata is fetched before proceeding
      }

      if (contentElement) {
        const sanitizedContent = sanitizeHTML(contentElement.innerHTML);
        const cookieName = generateCookieName(sanitizedContent);
        const cachedAudioUrl = localStorage.getItem(cookieName);

        if (cachedAudioUrl) {
          setAudioUrl(cachedAudioUrl);
          sendAudioRequest(cookieName, cachedAudioUrl);
          return;
        }

        setIsLoading(true);
        try {
          const audioData = await memoizedFetchAudioData(sanitizedContent);
          setAudioUrl(audioData.audio_url);
          localStorage.setItem('stb-tts-tmp-key', cookieName);
          localStorage.setItem(cookieName, audioData.audio_url);
        } catch (error) {
          setError("Failed to fetch audio data. Please try again later.");
        }
        setIsLoading(false);
      }
    };

    const debounceTimer = setTimeout(handleContentChange, 1000);

    return () => clearTimeout(debounceTimer);
  }, [contentSelector, generateCookieName, memoizedFetchAudioData, userMetadata]);

  // Initializes Plyr player when audio URL is available
  useEffect(() => {
    loopRef.current = setInterval(() => {
      if (audioUrl && typeof window !== "undefined" && window.Plyr) {
        clearInterval(loopRef.current);
        const player = new window.Plyr("#myaudioplayer", {
          speed: { selected: 1, options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] },
          controls: [
            'play',
            'rewind',
            'fast-forward',
            'progress',
            'current-time',
            'duration',
            'mute',
            'volume',
            'settings',
            'airplay',
          ],
        });

        const cookieName = localStorage.getItem('stb-tts-tmp-key');

        player.on('play', () => sendPlayerEvent('play', cookieName, audioUrl));
        //player.on('pause', () => sendPlayerEvent('pause', cookieName, audioUrl));
        player.on('ended', () => sendPlayerEvent('ended', cookieName, audioUrl));
        //player.on('seeked', () => sendPlayerEvent('seeked', cookieName, audioUrl));
        //player.on('volumechange', () => sendPlayerEvent('volumechange', cookieName, audioUrl));
        //player.on('ratechange', () => sendPlayerEvent('ratechange', cookieName, audioUrl));
      }
    }, 1000);

    return () => clearInterval(loopRef.current);
  }, [audioUrl, generateCookieName]);

  if (error) {
    return <div>{error}</div>;
  }

  if (isLoading) {
    return <div>Loading audio...</div>;
  }

  if (!audioUrl) {
    return <div>No audio available for this content.</div>;
  }

  return (
    <>
      <Helmet>
        <script async src="https://cdn.plyr.io/3.7.8/plyr.js" />
        <link rel="stylesheet" href="https://cdn.plyr.io/3.7.8/plyr.css" />
      </Helmet>
      {audioUrl && <p className="mb-1">Listen to this Article</p>}
      <div ref={audioPlayerElement} style={{ marginBottom: '1em' }}>
        {audioUrl && (
          <>
            <audio
              id="myaudioplayer"
              controls
              controlsList="nodownload"
              src={audioUrl} />
            <style>
              {`
                audio#myaudioplayer {
                  width: 100%;
                }
                .plyr__controls .plyr__controls__item.plyr__control[data-plyr="play"] {
                  border: 0.1rem solid #373737;
                  border-radius: 20px;
                }
                .plyr--audio .plyr__controls {
                  border-radius: 15px;
                }
                .plyr__controls {
                  font-family: sans-serif;
                }
                .plyr--audio {
                  display: block;
                  --plyr-color-main: #1c7430;
                  --plyr-audio-controls-background: #fbfbfb;
                  --plyr-control-radius: 5px;
                  --plyr-control-spacing: 15px;
                }
              `}
            </style>
          </>
        )}
      </div>
    </>
  );
};

export default StbTTSAudioPlayer;
